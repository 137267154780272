.table{
    width: 100%;
    border-collapse: collapse;
    position: relative;
    td,
    th {

        padding: 8px;

        border-top: 1px solid rgba(255,255,255,.3);
        &:first-child{
            border-left: 0;
        }

        &:last-child{
        }
    }
    th.align--left {
        text-align: left;
    }
    th.align--center {
        text-align: center;
    }
    th.align--right {
        text-align: right;
    }
    td.mark--yellow{
        background: #fff000;
        width: 5px;
        padding: 0;
    }
    td.mark--grey{
        background: #a2a2a2;
        width: 5px;
        padding: 0;
    }
    td.mark--orange{
        background: #bf842c;
        width: 5px;
        padding: 0;
    }
    td.mark--empty{
        background: transparent;
        width: 5px;
        padding: 0;
    }
    td.align--center{
        text-align: center;
    }
    td.align--right{
        text-align: right;
    }
    &--condensed{
        td,
        th {
            padding-left: 5px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-right: 5px;
        }
    }
    .nowrap{
        white-space: nowrap;
    }

    width: 100%;
    border-collapse: collapse;

    th, td {
        text-align: left;
        @media screen and (max-width: 900px){
            padding: 5px 10px;
        }
        @media screen and (max-width: 340px){
            padding: 5px;
        }
    }

    &--larger{
        font-size: 14px;
        th, td{
            padding: 10px 20px;
        }
    }
    &--stripped{
        tr:nth-child(odd) td{
            background: darken(#fff, 2);
        }
    }
    &--hover{
        tbody tr:nth-child(odd):hover td{
            background: darken(#fff, 7);
        }
        tbody tr:nth-child(even):hover td{
            background: darken(#fff, 7);
        }
    }
    &--responsive {
        @media only screen and (max-width: 800px) {
            thead {
                display: none;
            }
            tbody, tr, td {
                display: block;
            }
            tr td:last-child {
                padding-bottom:1px;
            }
            td::before {
                content: attr(data-t);
                display: block;
                color: rgba(0, 0, 0, 0.65);
                padding-bottom: 5px;
            }
            td {
                padding-left:10px;
                padding-right:10px;
                background: none;
                border: none;
                padding-top:1px;
                &.td--responsive-flex{
                    display: flex;
                    &::before{
                        margin-right: 10px;
                    }
                }
                &.td--responsive-flex--full{
                    flex: 1;
                }
            }
            td.hide-r {
                display: none;
            }
            tr {
                border-top: 1px solid #dadada;
                &:first-child {
                    border-top: 0;
                }
            }
            tbody:last-child > tr:last-child > td {
                border-bottom: 0;
            }
        }
    }
    &--dark-theme{
        background: lighten($grayColor3, 3);
        &.table--stripped {
            tr:nth-child(odd) td{
                background: lighten($grayColor3, 6);
            }
        }
        td::before {
            color: rgba(255,255,255,.75);
        }
        a {
            &:hover {
                color: $colorMain;
            }
        }
    }

    .clickable {
        cursor: pointer;
    }

    .clickable:hover {
        color: #ffb01b;
    }
}