body.no-scroll {
    overflow: hidden;
}

.panel-drop-background {
    content: '';
    z-index: 9888;
    position: fixed;
    background: #0b0b0b;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.5s;
    &--show {
        opacity: 0.8;
    }
}

.component-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    
    align-items: center;
    justify-content: center;
    z-index: 9999;

    &__window {
        width: 600px;
        max-width: 600px;
        min-height: 200px;
        background-color: white;
        padding: 20px;
        max-height: 80vh;
        overflow-y: scroll;
        
        h2 {
            margin-top: 0;
            margin-bottom: 20px;
        }
        .youtube {
            background: black;
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    
    &__field {
        margin-bottom: 20px;
        &__title {
            font-weight: 700;
            margin-bottom: 10px;
            &__action {
                font-weight: 600;
                float: right;
                color: $red2Color;
                cursor: pointer;
            }
        }
        &__content {
            width: 100%;
            input, textarea {
                width: 100%;
            }
            textarea {
                height: 100px;
            }
            &__with-action {
                display: flex;
                .btn {
                    margin-left: 10px;
                }
            }
        }
    }
}