.component__page-header{
    line-height: 1.15;
    // background: url('purple-gradient-bg-mini.jpg');
    background: $gradientPageHeader;
    background-size: cover;
    color: #fff;
    @media screen and (max-width: $maxWidth) {
        padding-left: 20px;
        padding-right: 20px;
        padding: 20px;
    }
    @media screen and (max-width: 1024px) {
        margin-top: 50px;
    }
    @media screen and (max-width: 640px) {
        padding: 20px 15px;
    }
    &__back {
        width: 30px;
        margin-right: 20px;
        align-self: center;
        @media screen and (max-width: $maxWidth){
            width: 25px;
        }
    }
    
    &__container {
        padding: 40px 0;
        max-width: $maxWidth;
        margin: 0 auto;
        padding: 0 $mainSpace;
        @media (max-width: 640px) {
            padding: 0 $mainSpaceMobile;
        }
        @media screen and (max-width: $maxWidth) {
            padding: 0;
        }
    }

    &__title {
        align-self: center;
        font-size: 40px;
        font-weight: 300;
        @media screen and (max-width: $maxWidth){
            font-size: 30px;
        }
        @media screen and (max-width: 640px){
            font-size: 22px;
            margin-bottom: 5px;
        }
        
        h1, h2{
            margin: 0;
            padding: 0;
            font-size: inherit;
            font-weight: inherit;
        }
    }
    &__category {
        font-weight: 300;
        font-size: 20px;
        margin-bottom: 5px;
        @media screen and (max-width: $maxWidth){
            font-size: 14px;
        }
    }
    
    &__subtitle {
        display: flex;
        flex-wrap: wrap;
        font-size: 16px;
        font-weight: 300;
        @media screen and (max-width: $maxWidth){
            font-size: 14px;
        }
        &__author {  
            margin: 12px 0 10px;
            @media screen and (max-width: 640px){
                flex-basis: 100%;
                margin: 5px 0;
            }
        }
        // &__views, &__votes {   
        //     margin-top: 10px;
        //     margin-left: 10px;
        //     border-left: 1px solid rgba(255,255,255,0.5);
        //     padding-left: 10px;
        //     @media screen and (max-width: 640px){
        //         margin-top: 5px;
        //     }
        // }
        // &__views {
        //     @media screen and (max-width: 640px){
        //         margin-left: 0;
        //         border: 0;
        //         padding-left: 0;
        //     }
        // }
        // &__votes {
        //     font-weight: 600;
        // }
    }

}