.item-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px,1fr));
    grid-gap: 30px 20px;
    margin: 25px 0 10px;
    .item-component {
        cursor: pointer;
        &:hover {
            .item-component__thumb img {
                transform: scale(1.1);
            }
            .item-component__title {
                color: $colorMain;
            }
        }
        &__thumb {
            padding-bottom: 56%;
            position: relative;
            overflow: hidden;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
                transform: scale(1);
                transition: transform 0.5s ease;
            }
        }
        &__category {
            //color: #555;
            margin: 10px 0 5px;
        }
        &__title {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.2em;
            // margin: 10px 0;
            margin-bottom: 7px;
            transition: color .5s ease;
            overflow: hidden;
            margin-top: 7px;
        }
        &__author {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            &__picture-container {
                
            }
            &__name {
                margin-left: 5px;
                width: 100%;
            }
        }
    }
}