.rankings {
    .ranking-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(260px,1fr));
        grid-gap: calc(#{$mainSpace} / 2);
        .panel {
            box-shadow: unset;
            border: unset;
            padding: 0;
            &__header {
                margin-bottom: calc(#{$mainSpace} / 4);
            }
        }
    }

    .ranking-header {
        display: flex;
        margin-bottom: calc(#{$mainSpace} / 2);

        &__title{
            flex: 1;
            font-weight: 700;
            font-size: 24px;
            text-transform: uppercase;
            font-style: italic;

            @media (max-width: 640px) {
                font-size: 22px;
                line-height: 1.2;
                margin-bottom: calc(#{$mainSpaceMobile} / 2);
            }
        }

        &__btn {

        }

        @media (max-width: 640px) {
            flex-direction: column;
            margin-bottom: calc(#{$mainSpaceMobile} / 2);
        }
    }
}

.challenge_ranking {
    .ranking-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(380px,1fr));
        grid-gap: 30px 20px;

        @media (max-width: 640px) {
            grid-template-columns: auto;
        }
    }
}

.view_entry {
    .entries-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(380px,1fr));
        grid-gap: 30px 20px;

        .panel {
            height: fit-content;
            &.panel-report {
                textarea {
                    margin-bottom: calc(#{$mainSpace} / 2);
                }
            }
        }
    }
}