.page--upload{
    .dnd-box{
        cursor: pointer;
        padding: 20px;
        // background: linear-gradient(0deg,#544583,transparent 70%),linear-gradient(90deg,#565c95,#6f73a3,#6e5d8d);
        background: $gradientUpload;
        background-size: cover;
        text-align: center;
        color: white;
        margin: 0 auto 20px auto;
        font-weight: 600;

        input{
            border: none;
            padding: 0;
            @media (max-width: 800px) {
                max-width: 70vw;
            }
        }

        &__icon {
            height: 50px;
            &:hover {
                color: $color2;
            }
        }
        &__container {
            border: 2px dashed white;
            padding: 40px;
            text-align: center;
            @media (max-width: 800px) {
                padding: 10px;
            }
        }
        &:hover {
            color: $color2;
            .dnd-box__container {
                border-color: $color2;
            }
        }
    }
    .video-selected-message{
        margin-bottom: 15px;
        background: #c8ffa1;
        padding: 10px;
        text-align: center;
        transform: rotateX(90deg);
        transition: transform .8s ease;
        &--rotate{
            transform: rotateX(0deg);
        }
    }
    .edit-thumbnail-title {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
        font-weight: 700;
        font-size: 16px;
    }
    .edit-thumbnail-image {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 400px;
    }
    .edit-thumbnail-buttons {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 60%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;

        .btn {
            width: 100%;
            background: unset;
            background-color: #e5e5e5;
            height: 30px;
            padding: 5px;
        }
    }
    .edit-thumbnail-save-container {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }
    .edit-thumbnail-save {
        text-align: center;

        .btn {
            width: 100%;
        }
    }
    .loader {
        width: 40px;

        svg {
            display: block;
        }
    }

    .hidden {
        display: none;
    }
}