
.profile_user {
    .ranking {
        padding: unset;
        margin-bottom: calc(#{$mainSpace} / 2);

        tr {
            border-top: 1px solid rgba(255, 255, 255, 0.3);
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            border-right: 1px solid rgba(255, 255, 255, 0.3);
        }

        @media only screen and (max-width: 640px) {
            margin-bottom: calc(#{$mainSpaceMobile} / 2);
        }
    }
}

.exercises_user {
    @media only screen and (max-width: 800px) {
        tr {
            padding-bottom: 10px;
        }

        td:nth-of-type(1) {
            display: none;
        }

        td:before {
            font-weight: 600;
            opacity: 0.75;
            color: #eee;
        }

        td:nth-of-type(2):before { content: "Title"; }
        td:nth-of-type(3):before { content: "Is exercise judo related"; }
        td:nth-of-type(4):before { content: "Number of required people"; }

        td:nth-of-type(5) {
            a {
                width: 100%;
                box-sizing: border-box;
            }
        }
    }
}

.challenges_user {
    @media only screen and (max-width: 800px) {
        tr {
            padding-bottom: 10px;
        }

        td:nth-of-type(1) {
            display: none;
        }

        td:before {
            font-weight: 600;
            opacity: 0.75;
            color: #eee;
        }

        td:nth-of-type(2):before { content: "Title"; }
        td:nth-of-type(3):before { content: "Type"; }
        td:nth-of-type(4):before { content: "Published"; }

        td:nth-of-type(5) {
            a {
                width: 100%;
                box-sizing: border-box;
            }
        }
    }
}
