.page--my_profile {
  .image_container {
    &__row {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
  }
}

.my_profile_dashboard {
  .ranking-grid {
    margin-bottom: $mainSpace;
  }

  .ranking {
    padding: unset;
    margin-bottom: calc(#{$mainSpace} / 4);

    tr {
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      border-right: 1px solid rgba(255, 255, 255, 0.3);
    }

    @media only screen and (max-width: 640px) {
      margin-bottom: calc(#{$mainSpaceMobile} / 2);
    }
  }
}

.my_profile_measurements {
  @media only screen and (max-width: 800px) {

    tr {
      padding-bottom: 10px;
    }

    td:nth-of-type(1) {
      display: none;
    }

    td:before {
      font-weight: 600;
      opacity: 0.75;
      color: #eee;
    }

    td:nth-of-type(2):before { content: "Type"; }
    td:nth-of-type(3):before { content: "Date"; }
    td:nth-of-type(4):before { content: "Value"; }

    td:nth-of-type(5) {
      a {
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
}

.my_profile_exercises,
.my_profile_challenges {
  @media only screen and (max-width: 800px) {

    tr {
      padding-bottom: 10px;
    }

    td:nth-of-type(1) {
      display: none;
    }

    td:before {
      font-weight: 600;
      opacity: 0.75;
      color: #eee;
    }

    td:nth-of-type(2):before { content: "Title"; }
    td:nth-of-type(3):before { content: "Status"; }
    td:nth-of-type(4):before { content: "Published"; }

    td:nth-of-type(5) {
      a {
        width: 49%;
        box-sizing: border-box;
      }
    }
  }
}
