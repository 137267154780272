.content-sidebar{
    display: flex;

    @media screen and (max-width: 1024px) {
        display: block;
    }

    &__links {
        flex: 1;
        background: #4b4b4b;
        padding: 10px 0px;
        box-shadow: 0 10px 20px rgba(0,0,0,.4);
        transition: box-shadow ease .5s, border-color ease .5s;
        border: 1px solid rgba(255,255,255,0.4);
        margin-right: calc(#{$mainSpace} / 2);
        height: 100%;
        min-width: 300px;
        max-width: 300px;

        @media screen and (max-width: 1024px) {
            min-width: unset;
            max-width: unset;
            width: 100%;
        }
    }

    &__content {
        flex: 3;
        background: #4b4b4b;
        padding: $mainSpace;
        box-shadow: 0 10px 20px rgba(0,0,0,.4);
        transition: box-shadow ease .5s, border-color ease .5s;
        border: 1px solid rgba(255,255,255,0.4);
        h2:first-child {
            margin-top: 0;
        }
        &:hover{
            box-shadow: 0 10px 30px rgba(0,0,0,.8);
            border-color: white;
        }

        @media screen and (max-width: 1024px) {
            margin-top: calc(#{$mainSpace} / 2);
        }
        @media (max-width: 640px) {
            margin-top: calc(#{$mainSpace} / 4);
            padding: $mainSpaceMobile;
        }
    }

    &__link {
        display: block;
        width: 100%;
        padding: 10px 20px;
        border-left-width: 5px;
        border-left-style: solid;
        border-color: rgba(0,0,0,0);


        &--active {
            border-color: $colorMain;
            font-weight: 600;
        }
    }
}