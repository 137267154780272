$blueColoxr: #36335d;
$orangeColor: #fd7c00;
$redColor: #ec1c1c;
$greyLineColor: #c9c9c9;
$goldColor: #f9ce89;
$goldColor2: #d59f46;
$red2Color: $goldColor;
$goldGradient: linear-gradient(-80deg, #a37a35,#dbb372,#a37a35);
$goldGradient2: linear-gradient(-80deg,#a37a35,#dbb372);
$goldLineColor: #f9ce89;
$goldBorderGradient: linear-gradient(-80deg, #d59f46, #ffd085, #d59f46);
$violet: #36335d;
$linkOnWhite: lighten($violet, 20);

$grayColor1: #5b5b5b;
$grayColor2: #434343;
$grayColor3: #3b3b3b;
$grayColor4: #252525;


//THEME COLORS
$gradientHeader: linear-gradient(to top, $grayColor2, $grayColor4);
$gradientHeaderReverse: linear-gradient(to bottom, $grayColor2, $grayColor4 );
$gradientMainOrange: linear-gradient(to right, $orangeColor, $redColor);
$gradientPageHeader: url('purple-gradient-bg-mini.jpg');
$gradientPageHeaderReverse: $gradientPageHeader;
$gradientUpload: $gradientHeaderReverse;
$colorMainMenu: #ffb01b;
$color2: $goldColor;
$colorMain: #ffb01b;
$colorMainDarker: $goldColor2;


$maxWidth: 1240px;
$headerHeight: 94px;
$headerHeightMobile: 50px;

$offsetHeight: 40px;
$mainSpace: 40px;
$mainSpaceMobile: 20px;

@mixin text-offset-effect($textOffsetDistance) {
    position: relative;
    display: inline-block;
    z-index: 1;
    &::after, &::before {
        content:attr(data-title);
        position: absolute;
        z-index: -1;
        -webkit-text-stroke: 1px rgba(255, 255, 255, 0.4);
        -webkit-text-fill-color: transparent;
        padding: 0 calc(#{$textOffsetDistance} / 2);
    }
    &::before {
        // color: blue;
        top: - $textOffsetDistance;
        left: - $textOffsetDistance;
    }
    &::after {
        // color: yellow;
        bottom: - $textOffsetDistance;
        right: - $textOffsetDistance;
    }
}

@mixin text-offset-effect-hover {
    &:hover {
        &::before {
            animation: text-offset-flicker-before 0.6s linear infinite;
        }
        &::after {
            animation: text-offset-flicker-after 0.7s linear infinite;
        }
    }
}

main {

    .main-content {
        max-width: $maxWidth;
        margin: 0 auto 40px;
        padding-top: 20px;
        padding-left: $mainSpace;
        padding-right: $mainSpace;
        @media (max-width: 640px) {
            padding-left: $mainSpaceMobile;
            padding-right: $mainSpaceMobile;
        }

        @media (max-width: $maxWidth) {
            padding-top: 0;
            margin-bottom: 20px;
        }
        &--with-page-header {
            padding-top: 0px;
        }
        &--no-bottom-margin {
            margin-bottom: 0px;
        }
        .content-box {
            flex: 1;
            margin-bottom: $mainSpace;
            @media all and (max-width: 1024px) {
                box-shadow: none;
                margin-bottom: 0;
                &:not(:first-child) {
                    border-top: 1px solid $greyLineColor;
                }
            }
            @media (max-width: 640px) {
                padding: $mainSpaceMobile;
                margin-bottom: calc(#{$mainSpaceMobile} / 2);
            }
            &__title {
                display: flex;
                padding-bottom: calc(#{$mainSpace} / 2);
                margin: 0;
                text-transform: uppercase;
                font-style: italic;
                & h1, h2{
                    font-size: 24px;
                    font-weight: 700;
                    margin: auto 20px auto 0;
                    padding: 0;
                    flex: 1;
                }
                h3 {
                    margin: auto 20px auto 0;
                    padding: 0;
                }
                .btn {
                    height: 0%;
                    align-self: center;
                    @media screen and (max-width: 640px) {
                        align-self: flex-start;
                        margin-top: 10px;
                    }
                }
                @media screen and (max-width: 640px) {
                    flex-direction: column;
                }
                &__with-subtitle {
                    display: block;
                    small {
                        opacity: 0.75;
                        font-style: italic;
                        font-size: 14px;
                        text-transform: none;
                    }
                }
            }
        }
    }

    .container {
        max-width: $maxWidth;
        width: 100%;
        position: relative;
        padding-left: $mainSpace;
        padding-right: $mainSpace;
        @media (max-width: 640px) {
            padding-left: $mainSpaceMobile;
            padding-right: $mainSpaceMobile;
        }
        &--offset {
            margin-top: - $offsetHeight;
            margin-bottom: calc(#{$mainSpace} * 2);
            @media (max-width: 640px) {
                margin-bottom: $mainSpace;
            }
        }
        &--slider {
            max-width: unset;
            padding: 0;
        }
    }

    .content-box {
        background: $grayColor3;
        padding: $mainSpace;
        box-shadow: 0 10px 20px rgba(0,0,0,.4);
        transition: box-shadow ease .5s, border-color ease .5s;
        border: 1px solid rgba(255,255,255,0.3);
        @media (max-width: 640px) {
            padding: $mainSpaceMobile;
        }
        &:hover {
            border-color: white;
        }
    }

    section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &.hero-fit{
            position: relative;

            .bg-video {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                overflow: hidden;
                video {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: auto;
                    height: auto;
                    min-width: 100%;
                    min-height: 100%;
                    transform: translate(-50%, -50%);
                }
            }

            color: white;
            height: 70vh;
            background: $gradientMainOrange;
            padding-top: $headerHeight;
            padding-bottom: $offsetHeight;
            @media (max-width: 1024px) {
                padding-top: $headerHeightMobile;
            }
            @media (max-width: 768px) {
                height: unset;
            }

            .container-hero  {
                z-index: 1;
                max-width: $maxWidth;
                width: 100%;
                display: flex;
                align-items: center;
                padding-left: $mainSpace;
                padding-right: $mainSpace;
                @media (max-width: 768px) {
                    flex-direction: column;
                    text-align: center;
                }
                @media (max-width: 640px) {
                    padding-left: $mainSpaceMobile;
                    padding-right: $mainSpaceMobile;
                    padding-bottom: $mainSpaceMobile;
                }
                &__text {
                    flex: 1;
                    margin: $mainSpace 0;
                    @media (max-width: 768px) {
                        margin-bottom: calc(#{$mainSpace} / 2);
                    }
                    &__subtitle {
                        font-size: 20px;
                        margin: 30px 0;
                        @media (max-width: 640px) {
                            font-size: 16px;
                        }
                    }
                    &__button {
                        border: 1px solid white;
                        display: inline-block;
                        padding: 15px;
                        font-size: 16px;
                        position: relative;
                        cursor: pointer;
                        &:after{
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            background: white;
                            opacity: .5;
                            content: '';
                            transition: all .5s ease;
                        }
                        &:hover{
                            &::after{
                                opacity: 0;
                                left: -10px;
                                right: -10px;
                                top: -10px;
                                bottom: -10px;
                            }
                        }
                    }
                }
                &__graphic {
                    img {
                        width: 44vh;
                        height: 44vh;
                        @media (max-width: 1024px) {
                            width: 34vh;
                            height: 34vh;
                        }
                        @media (max-width: 768px) {
                            width: 300px;
                            height: 300px;
                        }
                        @media (max-width: 420px) {
                            width: 260px;
                            height: 260px;
                        }
                    }
                }
            }
        }
        &.about {
            h3 {
                font-size: 16px;
                font-weight: 500;
                margin: 0;
                padding: 0;
            }
            @media (max-width: 640px) {
                text-align: center;
            }
        }
        &.home-desc {

        }
        &.challenges {

        }
    }
    
    .wide-banner{
        background: $gradientMainOrange;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: $mainSpace 0 calc(#{$mainSpace} + #{$offsetHeight} );
        position: relative;
        &__background-image {
            position: absolute;
            opacity: 0.12;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            top: 0;
        }
        &__actual_content{
            display: flex;
            align-items: center;
            @media (max-width: 640px) {
                flex-direction: column;
            }
        }
        &__icon {
            margin-right: calc(#{$mainSpace} / 2);
            @media (max-width: 640px) {
                margin-right: 0;
                margin-bottom: calc(#{$mainSpaceMobile});
            }
            img {
                display: block;
                width: 150px;
                height: 150px;
                @media (max-width: 640px) {
                    width: 130px;
                    height: auto;
                }
            }
        }
        &__titles{
            flex: 1;
            position: relative;
            @media (max-width: 640px) {
                text-align: center;
            }
        }
        &__subtitle {
            font-size: 16px;
            margin-top: calc(#{$mainSpace} / 2  );
        }
    }

}

.no-flicker{
    -webkit-backface-visibility: hidden;
    -webkit-transform: scale(1);
}

// HB - headerbody
.template-HB {
    flex: 1;
    &--scrollable {
        overflow-y: scroll;
    }
    &--with-header-offset{
        margin-top: 94px;
        @media (max-width: 1024px) {
            margin-top: 49px;
        }
    }
    &__header {
        position: fixed;
        z-index: 900;
        width: 100%;
        height: $headerHeight;
        color: #fff;
        background: $gradientHeader;
        transition: background-image ease .5s, border-bottom ease .5s;
        border-bottom: 1px solid hsla(0, 0%, 100%, .3);
        &--animable{
            background-image: linear-gradient(to bottom, rgba($grayColor2, .6), rgba($grayColor3, .6));
            border-bottom: 1px solid hsla(0, 0%, 100%, .2);
            &::before{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                background: $gradientHeader;
                transition: opacity .5s ease;
            }
        }
        &--with_bg{
            // border-bottom: 1px solid hsla(0, 0%, 100%, .3);
            &::before{
                opacity: 1;
            }
        }
        // border-bottom: 1px solid hsla(0, 0%, 100%, .3);
        @media (max-width: 1024px) {
            height: $headerHeightMobile;
        }
        .ijf-logo{
            display: flex;
            align-items: center;
            margin-left: 10px;
            img{
                width: 57px;
                height: 57px;
            }
            @media all and (max-width: 1024px) {
                img{
                    width: 35px;
                    height: 35px;
                }
            }
        }
    }
    &__headerlimit {
        max-width: $maxWidth;
        margin: 0 auto;
        height: 100%;
        padding-left: $mainSpace;
        padding-right: $mainSpace;
        @media (max-width: 1024px) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__headeritems {
        position: relative;
        display: flex;
        height: 100%;
        @media all and (max-width: 1024px) {
            justify-content: center;
        }
    }
    &__body {
        display: flex;
        justify-content: center;
        padding: 20px 0;
        @media all and (max-width: $maxWidth) {
            padding: 0;
        }
    }
    &__contentbox {
        background: #fff;
        flex: 1;
        box-shadow: 0 5px 7px rgba(0, 0, 0, .25);
        max-width: $maxWidth;
        padding: 20px;
        @media all and (max-width: 1024px) {
            box-shadow: none;
            padding: 20px 15px;
        }
    }
}

.required-asterisk{
    color: red;
}

.hidden{
    display: none !important;
}

.image-instead-of-video{
    // width: 100%;
    height: auto;
    margin-left: auto !important;
    display: block;
    margin-right: auto;
    max-width: 100%;

    &__zoom-in {
        cursor: zoom-in;
    }

    &__zoom-out {
        cursor: zoom-out;
    }
}

.btn-old {
    cursor: pointer;
    border: 0;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
    padding: 10px 15px;
    overflow: hidden;
    position: relative;
    border: 1px solid white;
    text-align: center;
    font-size: 14px;
    font-family: inherit;
    line-height: inherit;
    transition: all .5s ease;
    filter: brightness(100%);
    @media all and (max-width: 1024px) {
        padding: 8px 10px;
    }
    &::after {
        content: "";
        position: absolute;
        transition: all .5s ease;
        width: 0;
        height: 500%;
        background: hsla(0, 0%, 100%, .15);
        z-index: 1;
        right: 0;
        top: 0;
        margin: -5px 0 0 -5px;
        transform-origin: 0 0;
        transform: rotate(-20deg);
    }
    &:hover {
        filter: brightness(105%);
        &::after {
            width: 40%;
        }
    }
    &--medium {
        font-size: 16px;
        padding: 15px 20px;
    }
    &--big {
        font-size: 20px;
        padding: 20px 30px;
    }
    &--green {
        background-color: #03b500;
    }
    &--grey {
        color: #333;
        background-color: #e5e5e5;
        border: 1px solid #b9b9b9;
    }
    &--white {
        color: #333;
        background-color: #f9f9f9;
        border: 1px solid #b9b9b9;
    }
    &--nowrap {
        white-space: nowrap;
    }
    &--icon {
        cursor: pointer;
        border: 0;
    }
    &--fb_blue {
        background: #4267b2;
    }
    &--with-icon {
        display: inline-flex;
        align-items: center;
        svg {
            width: 16px;
            height: 16px;
            margin-right: 5px;
        }
        &-right {
            svg {
                margin-right: 0;
                margin-left: 5px;
            }
        }
    }
    &--with-icon-right {
        display: flex;
        align-items: center;
        svg {
            width: 16px;
            height: 16px;
            margin-left: 5px;
        }
    }
    &:disabled {
        cursor: default;
        background-color: #ccc;
        filter: none;
        &::after {
            display: none;
        }
    }
    &--ippon-black {
        position: relative;
        background: url('btn_ippon_black_bg.png');
        background-size: contain;
        border-radius: 5px;
        border: 1px solid #58585a;
        height: 60px;
        width: 220px;
        &::before {
            content:'';
            position: absolute;
            background-image: url('btn_ippon_black_txt.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            top: 12%;
            bottom: 12%;
            left: 12%;
            right: 12%;
        }
    }
    &--ippon-white {
        position: relative;
        background: url('btn_ippon_white_bg.png');
        background-size: contain;
        border-radius: 5px;
        border: 1px solid #a7a9ac;
        height: 60px;
        width: 220px;
        &::before {
            content:'';
            position: absolute;
            background-image: url('btn_ippon_white_txt.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            top: 12%;
            bottom: 12%;
            left: 12%;
            right: 12%;
        }
        &:hover{
            background: url('btn_ippon_black_bg.png');
            border: 1px solid #58585a;

        }
    }
}

.btn {
    cursor: pointer;
    border: 0;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
    padding: 10px 15px;
    position: relative;
    border: 1px solid rgba(255,255,255,0.5);
    text-align: center;
    font-size: 14px;
    font-family: inherit;
    line-height: inherit;
    transition: all .5s ease;
    @media all and (max-width: 1024px) {
        padding: 8px 10px;
    }
    &::after {
        content: "";
        position: absolute;
        transition: all .5s ease;
        background: white;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform-origin: center;
        transform: scaleX(1.2) scaleY(1.5);
        opacity: 0;
    }
    &:hover {
        border-color: rgba(255,255,255,1);
        &::after {
            opacity: 0.2;
            transform: scale(1);
        }
    }
    &--orange {
        background-color: transparentize($orangeColor, 0.4);
    }
    &--full-width {
        width: 100%;
    }
    &--medium {
        font-size: 16px;
        padding: 15px 20px;
    }
    &--small {        
        padding: 5px 12px;
    }
    &--big {
        font-size: 20px;
        padding: 20px 30px;
        @media (max-width: 640px) {
            font-size: 16px;
        }
    }
    &--green {
        background-color: #03b500;
    }
    &--grey {
        color: #333;
        background-color: #e5e5e5;
        border: 1px solid #b9b9b9;
    }
    &--white {
        color: #333;
        background-color: #f9f9f9;
        border: 1px solid #b9b9b9;
    }
    &--nowrap {
        white-space: nowrap;
    }
    &--icon {
        cursor: pointer;
        border: 0;
    }
    &--fb_blue {
        background: #4267b2;
    }
    &--with-icon {
        display: inline-flex;
        align-items: center;
        svg {
            width: 16px;
            height: 16px;
            margin-right: 5px;
        }
        &-right {
            svg {
                margin-right: 0;
                margin-left: 5px;
            }
        }
    }
    &--with-icon-right {
        display: flex;
        align-items: center;
        svg {
            width: 16px;
            height: 16px;
            margin-left: 5px;
        }
    }
    &:disabled {
        cursor: default;
        background-color: #ccc;
        filter: none;
        &::after {
            display: none;
        }
    }
}

select {
    cursor: pointer;
    height: 36px;
    padding: 0 10px;
    border: 1px solid rgba(255,255,255,0.4);
    font-weight: 500;
    font-family: inherit;
    text-overflow: ellipsis;
    background: $grayColor4;
    color: #eee;
    @media all and (max-width: 1024px) {
        height: 32px;
        padding: 0 5px;
    }
}

textarea, input {
    border: 1px solid rgba(255,255,255,0.4);
    font-family: inherit;
    background: $grayColor4;
    color: #eee;  
}

textarea {
    padding: calc(#{$mainSpace} / 4);
}

input {    
    height: 36px;
    padding: 0 calc(#{$mainSpace} / 4);
    @media all and (max-width: 1024px) {
        height: 32px;
        padding: 0 calc(#{$mainSpace} / 8);
    }
}

svg {
    height: 100%;
    width: 100%;
}

a {
    text-decoration: none;
    color: inherit;
    transition: color 0.1s linear;
    &:not(.btn, .btn-glow) {
        &:hover {
            color: $colorMainDarker;
        }
    }
}

.menulogo {
    align-self: center;
    margin-right: 20px;
    @media all and (max-width: 1024px) {
        margin-right: 0;
        margin-left: 0;
    }
    img {
        display: block;
        height: 62px;
        @media all and (max-width: 1024px) {
            height: 36px;
        }
    }
}

.menuitems {
    display: flex;
    width: 100%;
    align-items: flex-end;
    @media all and (max-width: 1024px) {
        display: none;
    }
    &__item {
        .btn {
            margin-bottom: 12px;
        }
    }
    &__item:not(div) {
        // &:last-child {
        //     padding-right: 0 !important;
        // }
        position: relative;
        padding: 20px 18px 22px;
        color: inherit;
        text-decoration: none;
        &::after {
            content: "";
            display: inline-block;
            position: absolute;
            bottom: 0;
            left: 50%;
            right: 50%;
            height: 5px;
            background: rgba(255, 21, 83, 0);
            transition: background-color .2s, left .2s, right .2s;
        }
        &:hover {
            color: inherit;
            &::after {
                left: 0;
                right: 0;
                background: hsla(0, 0%, 100%, .4);
            }
        }
    }
    a.menuitems__item {
        &--active {
            &::after {
                left: 0;
                right: 0;
                // background: hsla(0, 0%, 100%, .4);
                background: $colorMain;
            }
        }
    }
    div.menuitems__item {
        &--filler {
            flex-grow: 1;
        }
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.messages {
    .title {
        font-weight: 700;
        font-size: 16px;
        padding: 20px 0 20px;
        margin-top: 40px;
        border-top: 1px solid $greyLineColor;
    }
    .comment {
        margin-bottom: 10px;
        @media (max-width: 800px) {
            margin-bottom: 20px;
        }
        &__title_and_time {
            margin-bottom: 5px;
        }
        &__title, &__time, &__separator {
            display: inline-block;
            color: #555;
        }
        &__separator {
            padding: 0 5px;
        }
        &__message {
            display: block;
            margin-bottom: 2px;
            .text {
                display: inline-block;
                padding: 15px;
                border-radius: 10px;
            }
        }
        &--mine {
            text-align: right;
            .text {
                background: #d3d1eb;
            }
        }
        &--notmine {
            .text {
                background: #e4e4e4;
            }
        }        
    }
    .add_comment {
        display: flex;
        margin-top: 20px;
        @media (max-width: 500px){
            flex-direction: column;
        }
        &__comment{
            flex: 1;
            display: flex;
        }
        &__btns{
            display: flex;
            .btn {
                &:not(:first-child) {
                    margin-left: 5px;
                }
            }
        }
        textarea {
            flex: 1;
            margin-right: 10px;
            @media (max-width: 500px){
                margin-right: 0px;
                margin-bottom: 5px;
            }
        }
        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 100px;
            @media (max-width: 500px){
                flex: 1;
            }
        }
    }
}



@mixin bottom-line-decoration($color1, $height: 3px, $color2: $color1) {
    position: relative;

    &::after {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 50%;
        right: 50%;
        height: $height;
        background: transparentize($color1, 1.0);
        transition: background-color 0.4s, left 0.5s, right 0.5s;
    }

    &.hover::after,
    &:hover::after {
        left: 0;
        right: 0;
        background: transparentize($color2, 0.6);
    }

    &.active::after, &.selected::after {
        left: 0;
        right: 0;
        background: $color1;
    }
}

.icon-spin {
    -webkit-animation: icon-spin 2s linear infinite;
    animation: icon-spin 2s linear infinite;
}

@-webkit-keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes text-offset-flicker-before {
    0%{
        transform: translate(20px,5px);
    }
    20% {
        transform: translate(20px,5px);
    }
    20.01%{
        transform: translate(5px,20px);
    }
    40%{
        transform: translate(5px,20px);
    }
    40.01%{
        transform: translate(0,0);
    }
    60%{
        transform: translate(0,0);
    }
    60.01%{
        transform: translate(30px,20px);
    }
    80%{
        transform: translate(30px,20px);
    }
    80.1%{
        transform: translate(2px,7px);
    }
    100%{
        transform: translate(2px,7px);
    }
}    

@keyframes text-offset-flicker-after {
    0%{
        transform: translate(-14px,-5px);
    }
    20% {
        transform: translate(-14px,-15px);
    }
    20.01%{
        transform: translate(-20px,-15px);
    }
    40%{
        transform: translate(-20px,-5px);
    }
    40.01%{
        transform: translate(-7px,-2px);
    }
    60%{
        transform: translate(-7px,-2px);
    }
    60.01%{
        transform: translate(-20px,-2px);
    }
    80%{
        transform: translate(-20px,-2px);  
    }
    80.1%{
        transform: translate(0,0);
    }
    100%{
        transform: translate(0,0);
    }
} 

.edit_exercise .ProseMirror ul,
.edit_exercise .ProseMirror ol,
.view_exercise .content-box ol {
    padding: $mainSpace 0;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: transparentize($orangeColor, 0.8);
    box-shadow: inset 0 0 100px transparentize($orangeColor, 0.6),0 0 30px rgba(0,0,0,0.3);
    list-style: none;
    counter-reset: list-item-counter;
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto;
    li {
        counter-increment: list-item-counter;
        display: flex;
        align-items: center;
        padding: calc(#{$mainSpace} / 4) $mainSpace;
        


            // transition: background-position 0.5s;
            // background-repeat: no-repeat;
            // background-image: linear-gradient(to right, transparentize($colorMain, 0.8), transparent);
            // background-position: -340px;
            // cursor: pointer;
            // &:hover {
            //     background-position: 0;
            // }



        &::before {
            content: counter(list-item-counter, decimal-leading-zero);
            display: inline-block;
            font-size: 24px;
            font-style: italic;
            font-weight: 700;
            text-align: center;
            color: $colorMain;
            margin-right: calc(#{$mainSpace} / 2);
        }
        p {
            display: inline-block;
            padding: 0;
            margin: 0;
        }
    }
}

.avatar {
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    overflow: hidden;
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    position: relative;
    img {
        width: 18px;
        height: 18px;
        object-fit: contain;
    }
    &--white-border {
        border: 1px solid rgba(255, 255, 255, 0.5);
    }
    &--medium {
        height: 22px;
        width: 22px;
        min-height: 22px;
        min-width: 22px;
        img {
            width: 20px;
            height: 20px;
            object-fit: contain;
        }
    }
    &--big {
        height: 40px;
        width: 40px;
        min-height: 40px;
        min-width: 40px;
        border-width: 2px;
        img {
            width: 36px;
            height: 36px;
            object-fit: contain;
        }
    }   
}

.offset-title { 
    text-transform: uppercase;
    font-style: italic;
    font-weight: 700;
    font-size: 30px;
    @include text-offset-effect(10px);
    &--link {
        cursor: pointer;
        @include text-offset-effect-hover;
    }
    &--medium {
        font-size: 40px;
        margin: 0;
        padding: 0;
        @include text-offset-effect(13px);
        @media (max-width: $maxWidth) {
            font-size: 32px;
        }
        @media (max-width: 640px) {
            font-size: 22px;            
            @include text-offset-effect(10px);
        }
    }
    &--big {
        font-size: 50px;
        margin: 0;
        padding: 0;
        @include text-offset-effect(15px);
        @media (max-width: 640px) {
            font-size: 40px;
        }
    }
}

.challenge_add_entry{
    display: flex;
    &__data{
        flex: 1;
    }
    &__video{
        flex: 1;
    }
}

.pointer{
    cursor: pointer;
}

.loading {
    &:after{
        content: 'x';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(255,255,255,.3);
    }
}

.challenge_part_lb{ // leaderboards chooser for user in challenge
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    grid-gap: calc(#{$mainSpace} / 2);
    &--loading{
        opacity: .4;
    }
    &__box{
        border: 1px solid grey;
        height: 50px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: calc(#{$mainSpace} / 4);
        &--active{
            border-color: white;
            box-shadow: 0 0 10px black;
            background-color: transparentize($orangeColor, 0.4);
        }
    }
}

.fa--orange{
   color: lighten($colorMain,20);
   transition: color 0.1s linear;
   &:hover {
       color: $colorMain;
   }
}

.nowrap{
    white-space: nowrap;
}
