.component-mobile-menu {
    $menuWidth: 270px;

    @media all and (max-width: 1024px){
        display: block;
    }

    overflow: auto;
    position: fixed;
    display: none;
    background-color: #fff;
    z-index: 10000;
    bottom: 0;
    top: 0;
    right: 0;
    width: 0;

    transition: width .5s;

    &--opened {
        width: $menuWidth;
    }

    &__content {
        width: $menuWidth;
        display: flex;
        flex-direction: column;
    }

    &__separator {
        width: 100%;
        border-top: 1px solid $greyLineColor;
    }

    &__item {
        cursor: pointer;
        color: #000;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        font-weight: bold;
        width: 100%;
        padding: 10px;
        border-left: 5px solid #fff;
        &__title {
            flex: 1;
        }
        &__arrow {
            width: 20px;
            transition: transform .5s;
            &--down {
                transform: rotate(180deg);
            }
        }
        &__icon {
            width: 20px;
        }
        &:hover, &--active {
            border-color: $colorMain;
        }
        &--report-bug {
            color: #fff !important;
            background-color: $colorMain;
            border-color: $colorMain;
        }
        &--sign-in {
            background: $colorMain;
            color: #fff;
            border: 0;
            padding-left: 13px;
        }
    }

    &__product-group {
        display: flex;
        flex-wrap: wrap;
    }

    &__product {
        color: #333;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33.33%;
        padding: 10px;
        text-align: center;

        &__icon {
            height: 30px;
            width: 30px;
            background-size: contain;
            background-repeat: no-repeat;
        }

        &__name {
            position: relative;
            margin-top: 5px;
            width: 100%;

            &--coming-soon {
                &:after {
                    content: "Coming soon";
                    position: absolute;
                    bottom: -11px;
                    left: 0;
                    right: 0;
                    font-size: 10px;
                    opacity: 0.6;
                }
            }
        }
    }

    &__submenu {
        background-color: #e2e2e2;
        flex-direction: column;
        display: flex;
        transform-origin: top;
        transform: scaleY(0);
        transition-property: transform, max-height;
        transition-duration: 0.5s;
        max-height: 0;
        &--opened {
            transform:scaleY(1);
            max-height: 50vh;
        }
        overflow: hidden;
    }

    &__head {
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: 85px;
        width: 100%;
        // background: url('./purple-gradient-bg-mini.jpg');
        background: $gradientHeaderReverse;
        &__exit {
            right: 0;
            top: 0;
            padding: 10px;
            position: absolute;
            color: #fff;
            cursor: pointer;
            svg {
                height: 25px;
                width: 25px;
            }
        }
        &__logo {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px;
            img {
                height: 45px;
            }
        }
        &__user {
            padding-bottom: 20px;
            text-align: center;
            color: #fff;
        }
    }
    &--dark-theme {
        background-color: $grayColor3;
        color: #eee; 
        .component-mobile-menu__item {
            color: #eee;
            background-color: $grayColor3;
            border-color: $grayColor3;
            &:hover, &--active {
                border-color: $colorMain;
            }
            &--sign-in {
                background: $colorMain;
                color: #fff;
            }
        }
        .component-mobile-menu__product {
            color: #eee; 
        }
        .component-mobile-menu__separator {
            border-color: rgba(255,255,255,0.3);
        }
    }
}