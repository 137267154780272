.slider {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: -$mainSpace 0;
    padding: $mainSpace 0;
    &:hover {
        .slider__left-button, .slider__right-button {
            opacity: 1;
        }
    }
    &__outer-container {
        width: 100%;
        max-width: $maxWidth;
    }
    &__container {
        padding-left: $mainSpace;
        padding-right: $mainSpace;
        @media (max-width: 640px) {
            padding-left: $mainSpaceMobile;
            padding-right: $mainSpaceMobile;
        }
    }

    &__items {
        position: relative;
        display: flex;
        align-items: stretch;
        list-style: none;
        transition: transform .5s ease-out;
        width: 100%;
        // white-space: nowrap;
        padding: 0;
        border: 0;
        outline: 0;
        margin: 0;
        .item {
            cursor: pointer;
            width: 100%;
            min-width: calc((100% - ((#{$mainSpace} / 4) * 6)) / 4);
            max-width: calc((100% - ((#{$mainSpace} / 4) * 6)) / 4);
            background: $grayColor3;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
            transition: box-shadow ease 0.5s, border-color ease 0.5s;
            border: 1px solid rgba(255,255,255,0.3);
            margin: 0 calc(#{$mainSpace} / 4);
            @media (max-width: 1200px) {
                min-width: calc((100% - ((#{$mainSpace} / 4) * 4)) / 3);
                max-width: calc((100% - ((#{$mainSpace} / 4) * 4)) / 3);
            }
            @media (max-width: 640px) {
                min-width: calc(100% - (#{$mainSpaceMobile} / 1));
                max-width: calc(100% - (#{$mainSpaceMobile} / 1));
            }
            &:hover {
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.8);
                border-color: white;
                .item__image__background img {
                    transform: scale(1.1);
                }
            }
            &:first-child {
                margin-left: 0;
                @media (max-width: 640px) {
                    margin-left: calc(#{$mainSpace} / 4);                        
                }
            }
            &:last-child {
                margin-right: 0;
            }
            &__image {
                position: relative;
                overflow: hidden;
                &__background {
                    position: relative;
                    padding-bottom: 56%;
                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        display: block;
                        object-fit: cover;
                        transform: scale(1.0);
                        transition: transform 0.5s ease;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 50%;
                        background: linear-gradient(to bottom, transparent, rgba(0,0,0,0.6));
                    }
                }
            }
            &__author {
                position: absolute;
                left: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                padding: calc(#{$mainSpace} / 4);
                z-index: 1;
                &__profile {
                    display: flex;
                    align-items: center;
                    border: 1px solid rgba(0, 0, 0, 0.3);
                    border-radius: 50%;
                    overflow: hidden;
                    height: 22px;
                    width: 22px;
                    position: relative;
                }
                &__name {
                    font-weight: 500;
                    padding: calc(#{$mainSpace} / 8)
                }                
            }
            &__verified {
                display: inline-block;
                svg {
                    display: block;
                    height: 16px;
                    width: 16px;
                }
            }
            &__texts {                   
                padding: calc(#{$mainSpace} / 2);
            }
            &__title {
                font-weight: 600;
                font-size: 20px;
                white-space: normal;
            }
            &__country {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: calc(#{$mainSpace} / 8);
                img {
                    width: 20px;
                    height: 14px;
                    border: 1px solid rgba(255,255,255,0.5);
                    margin-right: 5px;
                }
            }
            &--portrait {
                margin-top:calc( #{$mainSpace} / 2 );
                min-width: calc((100% - ((#{$mainSpace} / 4) * 8)) / 5);
                max-width: calc((100% - ((#{$mainSpace} / 4) * 8)) / 5);
                @media (max-width: 1200px) {
                    min-width: calc((100% - ((#{$mainSpace} / 4) * 6)) / 4);
                    max-width: calc((100% - ((#{$mainSpace} / 4) * 6)) / 4);
                }
                @media (max-width: 900px) {
                    min-width: calc((100% - ((#{$mainSpace} / 4) * 4)) / 3);
                    max-width: calc((100% - ((#{$mainSpace} / 4) * 4)) / 3);
                }
                @media (max-width: 640px) {
                    min-width: calc(100% - (#{$mainSpaceMobile} / 1));
                    max-width: calc(100% - (#{$mainSpaceMobile} / 1));
                }
                .item__image {
                    width: 140px;
                    margin-top:calc( -#{$mainSpace} / 2 );
                    margin-left: auto;
                    margin-right: auto;
                    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
                    &__background {
                        padding-bottom: unset;
                        img {
                            position: relative;
                        }
                    }
                }
                .item__texts {
                    text-align: center;
                    padding-top: calc( #{$mainSpace} / 4 );
                    .item__verified {

                    }
                }
            }
        }
    }

    

    &__left-button, &__right-button {
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(255,255,255,0.75);
        border: 1px solid white;
        width: 50px;
        height: 60px;
        opacity: 0;
        transition: opacity 0.5s ease, background-color 0.5s ease;
        box-shadow: 0 10px 16px 0 rgba(0,0,0,0.3);
        color: #333;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 640px) {
            display: none;
        }
        &:hover {
            &::after {
                transform: scaleX(1);
            }
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(255,255,255,0.6);
            z-index: -1;
            transform: scaleX(0.0);
            transition: transform 0.5s ease;
        }
        svg {
            width: 20px;
            height: 20px;
            transform: rotate(0deg);
            transition: transform 0.5s ease;
        }
    }

    &__left-button {
        left: 0;
        &::after {
            transform-origin: right;
        }
        &:hover {
            svg {
                transform: rotate(-360deg);
            }
        }
    }

    &__right-button {
        right: 0;
        &::after {
            transform-origin: left;
        }
        &:hover {
            svg {
                transform: rotate(360deg);
            }
        }
    }
}