.view_challenge  {
    .challenge-info {
        display: flex;
        @media (max-width: 900px) {
            flex-direction: column;
        }
        &__exercises {
            flex: 1;
            margin-right: calc(#{$mainSpace} / 2);
            @media (max-width: 900px) {
                margin-right: 0;
            }
        }
        &__description {
            margin-left: calc(#{$mainSpace} / 2);
            flex: 1;
            @media (max-width: 900px) {
                margin-left: 0;
                margin-top: $mainSpace;
            }
            @media (max-width: 640px) {
                margin-top: $mainSpaceMobile;
            }
            .description {
                &__text {
                    margin-bottom: calc(#{$mainSpace} / 2);
                }
                &__short {                    
                    padding: $mainSpace;
                    border: 1px solid rgba(255, 255, 255, 0.3);
                    background-color: transparentize($orangeColor, 0.8);
                    box-shadow: inset 0 0 100px transparentize($orangeColor, 0.6),0 0 30px rgba(0,0,0,0.3);
                    font-size: 20px;
                    text-align: center;
                    @media (max-width: 900px) {
                        padding: $mainSpaceMobile;
                    }
                    p {
                        display: inline-block;
                        margin: 0;
                    }
                    &__type, &__variable {
                        padding: 0 10px;
                        font-weight: 700;
                        font-size: 26px;
                        color: $colorMain;
                    }
                }
            }
        }
    }
    .challenge_add_entry {
        display: flex;
        margin-bottom: $mainSpace;
        padding-bottom: $mainSpace;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        @media (max-width: 900px) {
            flex-direction: column;
            border-bottom: 0;
        }
        @media (max-width: 640px) {
            margin-bottom: $mainSpaceMobile;
            padding-bottom: $mainSpaceMobile;
        }
        &__container {
            flex: 1;
            display: flex;
            flex-direction: column;
            @media (max-width: 900px) {
                flex-direction: row;
            }
            &:not(:first-child) {
                margin-left: $mainSpace;
                @media (max-width: 900px) {
                    flex-direction: row;
                    margin-top: $mainSpaceMobile;
                    margin-left: 0;
                }
            }
        }
        &__timeline {
            display: flex;
            margin-bottom: calc(#{$mainSpace} / 4);
            align-items: center;
            color: $colorMain;
            font-size: 24px;
            font-weight: 700;
            line-height: 1;
            @media (max-width: 900px) {
                flex-direction: column;
                margin-right: $mainSpaceMobile;
                margin-bottom: 0;
            }
            &::after {
                content: '';
                position: relative;
                height: 1px;
                width: 100%;
                background: rgba(255, 255, 255, 0.5);
                margin-left: 10px;
                @media (max-width: 900px) {
                    width: 1px;
                    height: 100%;
                    margin-left: 0;
                    margin-top: 5px;
                }

            }
        }
        &__data {
            display: flex;
            align-content: flex-start;
            flex-wrap: wrap;
            &__input {
                display: flex;
                align-items: center;
                margin-bottom: calc(#{$mainSpace} / 4);
                &:not(:last-child) {
                    margin-right: calc(#{$mainSpace} / 2);
                }
                .input-label {
                    display: inline-block;
                    margin-right: 5px;
                }
                input {
                    margin-right: 5px;
                }
            }
        }
        &__video {
            .btn {
                margin-bottom: calc(#{$mainSpace} / 4);
            }
        }
        &__message {
            opacity: 0.75;
            font-style: italic;
        }
        &__save {
        }
    }

    .your_entries {
        .icon {
            // text-align: center;
            svg {
                width: 20px;
                height: 20px;

                cursor: pointer;

                &:hover {
                    color: $colorMain;
                }
            }
        }
    }

}