.view_entry {
	.reasons {
		width: 100%;
		.reason {
			display: block;
			width: 100%;
			margin-bottom: calc(#{$mainSpace} / 2);
			label {
				font-weight: 600;
				margin-bottom: 0.5em;
				opacity: 0.75;
			}
			&__text {
				font-size: 15px;
			}
		}
	}
}