.component--filters {
	.filters-row {
		.select-rank-by {
			flex: 1;
		}

		.filter-rank-by {
			flex: 2;
		}
	}
}

.grid {
	display: grid;
	width: 100%;

	&--3-2-1{
		grid-template-columns: calc(1/3*100% - (1 - 1/3)* (#{$mainSpace} / 2))
		calc(1/3*100% - (1 - 1/3)* (#{$mainSpace} / 2))
		calc(1/3*100% - (1 - 1/3)* (#{$mainSpace} / 2));
		grid-gap: calc(#{$mainSpace} / 2);
	}
	@media screen and (max-width: 900px){
		&--3-2-1{
			grid-template-columns: 100%;
		}
	}
}

.ranking {
	padding: calc(#{$mainSpace} / 2);
	.component--filters {
		.filters-row {
			margin-bottom: 10px;
			.one-filter {
				position: relative;
				height: 55px;
				overflow-x: auto;
				overflow-y: hidden;
				.ButtonsSelect {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					.opt {
						white-space: nowrap;
					}
				}
			}
		}
	}
	.table {
		border: 1px solid rgba(255, 255, 255, 0.3);
		.name {
			width: 40%;
			//@media (max-width: 640px) {
			//	width: 45%;
			//}
		}
		.points {
			// text-align: left;
			// padding-right: 0px;
			@media (max-width: 640px) {
				width: unset;
			}
		}
		td:last-child {
			@media (max-width: 640px) {
				padding-right: 10px;
			}
		}

		td.mark--yellow{
			background: #fff000 !important;
			width: 5px;
			min-width: 3px;
			padding: 0;
		}
		td.mark--grey{
			background: #a2a2a2 !important;
			width: 5px;
			min-width: 3px;
			padding: 0;
		}
		td.mark--orange{
			background: #bf842c !important;
			width: 5px;
			min-width: 3px;
			padding: 0;
		}
		td.mark--empty, th.mark--empty{
			background: transparent;
			width: 5px;
			min-width: 3px;
			padding: 0;
		}
		td.align--center{
			text-align: center;
		}
	}
}

.table {
	table-layout: fixed;
	box-sizing: content-box;
	th {
		border-top: 0;
		font-size: 14px;
	}
	tr.user-row {
		td {
			background-color: #e7e8f8;
			font-weight: 700;
			border-top-color: #c4c4c4;
		}
	}
	tr.user-row + tr  {
		td {
			border-top-color: #c4c4c4;
		}
	}
	th.check {
		svg {
			width: 15px;
		}
	}
	td {
		padding-top: 6px;
		padding-bottom: 6px;
	}
	.image {
		padding-right: 0;
		width: 20px;
		&__container {
			display: flex;
			align-items: center;
			border: 1px solid rgba(0,0,0,0.3);
			border-radius: 50%;
			overflow: hidden;
			height: 20px;
			width: 20px;
			position: relative;
			img {
				width: 100%;
				display: block;
			}
		}
	}
	.rank {
		width: 10px;
		@media (max-width: 768px) {
			width: 15px;
			padding-left: 5px;
			padding-right: 0;
		}
	}
	.name {
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		@media (max-width: 640px) {
			padding-right: 0px;
		}
		&__badge {
			display: inline-block;
			color: blue;
			font-size: 11px;
			font-weight: 700;
			margin-right: 4px;
			svg {
				height: 13px;
				vertical-align: middle;
			}
		}
	}

	.watch {
		text-align: right;
		padding: 0;
		a{
			padding: 5px 10px;
			svg{
				width: 12px;
				height: 12px;
			}
		}
	}

	.points {
		text-align: right;
		padding-right: 8px;
	}
}

.panel {
	width: 100%;
	background: #3b3b3b;
	padding: calc(#{$mainSpace} / 2);
	box-shadow: 0 10px 20px rgba(0,0,0,.4);
	transition: box-shadow ease .5s, border-color ease .5s;
	border: 1px solid rgba(255,255,255,0.3);
	&:hover {
		border-color: white;
	}
	&--spacing_top{
		margin-top: 20px;
	}
	&--spacing_bottom{
		margin-bottom: 20px;
	}
	&--spacing_left{
		margin-left: 20px;
	}
	&--spacing_right{
		margin-right: 20px;
	}
	&__header{
		display: flex;
		position: relative;
		align-items: center;
		margin-bottom: calc(#{$mainSpace} / 2);
		h2{
			font-size: 18px;
			font-weight: 600;
			margin: 18px 0;
			padding: 0;
			flex: 1;
		}
		h3{
			font-size: 16px;
			font-weight: 600;
			margin: 19px 0;
			padding: 0;
			flex: 1;
		}
		&__title-link {
			cursor: pointer;
			font-size: 16px;
			font-weight: 600;
			flex: 1;
			transition: color 0.2s ease;
			&:hover {
				color: $colorMain;
			}
		}
		select{
			width: auto;
			font-size: 12px;
			height: 30px;
			padding: 5px 10px;
			align-self: center;
		}
	}
	&__body{
		padding: 18px 20px;
		@media (max-width: 640px) {
			padding: 18px 15px;
		}
	}
	table{
		border: 1px solid rgba(255, 255, 255, 0.3);
		&.table--no-header{
			tr:first-child{
				td{
					border-top: 0;
				}
			}
		}
		tr:first-child{
			th{
				border-top: 0;
			}
		}
		td, th{
			border-color: rgba(255, 255, 255, 0.3);
		}
	}
	.panel-footer{
		border-top: 1px solid rgba(255, 255, 255, 0.3);
		padding: 20px;
	}
	&--header{
		font-size: 1rem;
		padding: 20px;
		font-weight: 600;
		border-bottom: 1px solid rgba(255, 255, 255, 0.3);
		@media (max-width: 640px) {
			padding: 10px;
		}
		&__small {
			font-size: .8rem;
			padding: .2rem 0;
			color: #777;
		}
	}
}

