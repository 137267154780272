.home {
    .container {
        .panel {
            .table {
                .rank {
                    width: 26px;
                    padding-right: 0;
                }
                .name {
                    width: unset;
                }
                .points {
                    padding-left: 0;
                }
            }
        }
    }
}