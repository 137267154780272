.component-accountbar{
    display: flex;
    align-items: center;
    @media (max-width: 1024px) {
        display: none;
    }
    .accountbar {
        &__cart {
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 0px 10px;
            height: 100%;

            .cart-icon {
                margin-right: 5px;

                svg {
                    transition: color 0.5s ease;
                    height: 22px;
                    width: 22px;
                    vertical-align: middle;
                }
            }

            .cart-items-number {
                border-radius: 50%;
                width: 19px;
                height: 19px;
                padding: 3px;
                background: $colorMainMenu;
                text-align: center;
                font-size: 10px;
                font-weight: 700;
                color: initial;
            }

            &:hover {
                svg {
                    color: $colorMainMenu;
                }
            }
        }
        &__languages {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0px 10px;
            height: 100%;

            .language-text {
                text-align: center;
                font-size: 12px;
            }

            .language-icon {
                text-align: center;
                svg {
                    transition: color 0.5s ease;
                    height: 15px;
                    width: 15px;
                    vertical-align: middle;
                }
            }

            &:hover {
                svg {
                    color: $colorMainMenu;
                }
            }
        }
        &__separator {
            display: block;
            height: 14px;
            width: 1px;
            background: rgba(255,255,255,0.5);
            margin: auto 10px;
        }
        &__products {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0px 10px;
            height: 100%;
            margin-right: 5px;
            svg {
                transition: color 0.5s ease;
                height: 22px;
                width: 22px;
                vertical-align: middle;
            }
            &:hover {
                svg {
                    color: $colorMainMenu;
                }
            }
        }
        &__user_profile {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0px 10px;
            height: 100%;
            user-select: none; /* supported by Chrome and Opera */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            .profile_image {
                border: 1px solid rgba(255,255,255,0.5);
                border-radius: 50%;
                overflow: hidden;
                height: 30px;
                width: 30px;
                position: relative;
                // &::after {
                //     content:'';
                //     position: absolute;
                //     top: 0;
                //     left: 0;
                //     right: 0;
                //     bottom: 0;
                //     background: white;
                //     transition: opacity 0.5s ease;
                //     opacity: 0;
                // }
                &::before {
                    content: "";
                    position: absolute;
                    transition: all 0.5s ease;
                    width: 0;
                    height: 500%;
                    background: rgba(255, 255, 255, 0.35);
                    z-index: 1;
                    right: 0;
                    top: 0;
                    margin: -5px 0 0 -5px;
                    transform-origin: 0 0;
                    transform: rotate(-20deg);
                }
                img {
                    width: 100%;
                }
            }
            &:hover {
                .profile_image::before {
                    // opacity: 0.3;
                    width: 75%;
                }
            }
        }
    }
}