#contentEditor {
    &.contentEditor--dark-theme {
        .ProseMirror-menubar-wrapper { 
            border: 1px solid rgba(255,255,255,0.3);
    
            .ProseMirror-menubar{
                padding: 10px;
                background: lighten($grayColor2, 5);
                color: #eee;
                border-color: rgba(255,255,255,0.3);
                .ProseMirror-menuseparator {
                    border-color: rgba(255,255,255,0.2);
                }
            }
            .ProseMirror{
                background: lighten($grayColor4, 3);
                color: #eee;
                padding: calc(#{$mainSpace} / 2 );
            }
        }
    }
}

.ProseMirror-prompt {
    background: $grayColor3;
    padding: calc(#{$mainSpace} / 2 );
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    max-width: 320px;
    h5 {
        color: #eee;
        font-weight: 700;
        margin-bottom: calc(#{$mainSpace} / 4 );
    }
    & input[type="text"], & textarea {
        background: $grayColor4;
        color: #eee;  
        margin-top: calc(#{$mainSpace} / 4 );
        width: 100%;
    }
}

#exercise_poster {
    height: 150px;
    width: 300px;
    object-fit: cover;
}
