.ijf-form {
    font-size: 14px;

    .contentEditorContainer {
        margin-bottom: 2em;
        
        .loader {
            padding: 60px;
            width: 200px;

            svg {
                display: block;
            }
        }

        .form-label {
            display: block;
            font-weight: 700;
            margin-bottom: 0.5em;
        }

    }

    .line {
        display: flex;
        margin-bottom: 15px;
        @media (max-width: 800px) {
            flex-direction: column;
        }
        &--center {
            justify-content: center;
        }

        .line-element {
            flex: 1;
            margin-left: 15px;
            @media (max-width: 800px) {
                margin-left: 0;
                margin-top: 15px;
            }
            &:first-child {
                margin-left: 0;
                @media (max-width: 800px) {
                    margin-top: 0;
                }
            }

            &--hidden {
                display: none;
            }

            &--checkbox {
                display: flex;
                align-items: center;

                input {
                    margin-left: 5px;
                    width: unset;
                }
            }
        }

        label {
            display:block;
            font-weight: 600;
            margin-bottom: 0.5em;
            opacity: 0.75;
        }

        input, textarea, select {
            border: 1px solid rgba(255,255,255,0.4);
            padding: 8px;
            width: 100%;
            font-size: 14px;
            box-sizing: border-box;
            height: 38px;
            background: $grayColor4;
            color: #eee;
        }

        textarea {
            width: 100%;
            height: 5em;
        }

        button {
            width: 100%;
        }

        .multiple-options {
            display: inline-block;
        }

        .label-checkbox {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-weight: 400;
            height: 24px;

            .input_field{
                margin-right: 5px;
                width: 20px;
            }
        }
        &--agree{
            input[type="checkbox"]{
                height: 15px;
                width: 15px;
                display: inline-block;
            }
            input[type="text"]{
                display: inline-block;
                width: 150px;
            }
            a{
                text-decoration: underline;
            }
        }

        .align-right {
            margin-left: auto;
            margin-right: 0px;

            @media screen and (max-width: 800px){
                margin-left: 0px;
                margin-right: 20px;
            }
        }
    }
}