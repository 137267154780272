/* open-sans-300 - latin-ext_latin */
@font-face {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 300;
		src: url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
		src: local('Open Sans Light'), local('OpenSans-Light'),
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-300.woff') format('woff'), /* Modern Browsers */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-300.svg#OpenSans') format('svg'); /* Legacy iOS */
	}

	/* open-sans-regular - latin-ext_latin */
	@font-face {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 400;
		src: url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
		src: local('Open Sans Regular'), local('OpenSans-Regular'),
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
	}

	/* open-sans-italic - latin-ext_latin */
	@font-face {
		font-family: 'Open Sans';
		font-style: italic;
		font-weight: 400;
		src: url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot'); /* IE9 Compat Modes */
		src: local('Open Sans Italic'), local('OpenSans-Italic'),
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
	}

	/* open-sans-600 - latin-ext_latin */
	@font-face {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 600;
		src: url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-600.eot'); /* IE9 Compat Modes */
		src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-600.woff') format('woff'), /* Modern Browsers */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-600.svg#OpenSans') format('svg'); /* Legacy iOS */
	}

	/* open-sans-700 - latin-ext_latin */
	@font-face {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 700;
		src: url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
		src: local('Open Sans Bold'), local('OpenSans-Bold'),
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700.svg#OpenSans') format('svg'); /* Legacy iOS */
	}

	/* open-sans-700italic - latin-ext_latin */
	@font-face {
		font-family: 'Open Sans';
		font-style: italic;
		font-weight: 700;
		src: url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot'); /* IE9 Compat Modes */
		src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
			url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
			url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
			url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
			url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
	}

	/* open-sans-800 - latin-ext_latin */
	@font-face {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 800;
		src: url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-800.eot'); /* IE9 Compat Modes */
		src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-800.woff2') format('woff2'), /* Super Modern Browsers */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-800.woff') format('woff'), /* Modern Browsers */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-800.ttf') format('truetype'), /* Safari, Android, iOS */
				 url('fonts/open-sans-v17-latin-ext_latin_cyrillic-ext_cyrillic-800.svg#OpenSans') format('svg'); /* Legacy iOS */
	}

	/* open-sans-800italic - latin-ext_latin */
	@font-face {
		font-family: 'Open Sans';
		font-style: italic;
		font-weight: 800;
		src: url('fonts/open-sans-v17-latin-ext_latin-800italic.eot'); /* IE9 Compat Modes */
		src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
			url('fonts/open-sans-v17-latin-ext_latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('fonts/open-sans-v17-latin-ext_latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
			url('fonts/open-sans-v17-latin-ext_latin-800italic.woff') format('woff'), /* Modern Browsers */
			url('fonts/open-sans-v17-latin-ext_latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
			url('fonts/open-sans-v17-latin-ext_latin-800italic.svg#OpenSans') format('svg'); /* Legacy iOS */
	}
  

*, *:before, *:after {
		box-sizing: inherit;
		//font-weight: normal;
}

@import 'normalize.css';

html {
	width:100%;
	height: 100%;
	box-sizing: border-box;
	padding: 0;
	color: #eee;
	background: #252525;
	font-family: 'Open Sans';
	line-height: 1.5;
	font-size: 14px;
	@media all and (max-width: 1024px){
		height: auto;
		width: 100%;
	}
	body {
		padding: 0;
		margin: 0;
		width:100%;
		min-height: 100%;
		display: flex;
		flex-direction: column;
		main {
			flex-grow: 1;
		}
	}
	@import './shared.scss';
	@import './upload.scss';
	@import './table.scss';
	@import './form.scss';
	@import './myProfile.scss';
	@import './PageContestBrowserStyle.scss';
	@import './ComponentFilters.scss';
	@import './browse.scss';
	@import './ranking.scss';
	@import './watch.scss';
	@import './ComponentArticleShare.scss';
	@import './ComponentItemGrid.scss';
	@import './ModalComponent.scss';
	@import "./ComponentMobileMenu.scss";
	@import "./ComponentTimeline.scss";
	@import 'ComponentPageHeaderStyle.scss';
	@import 'ComponentPageHeaderV2.scss';
	@import 'ComponentAccountBar.scss';
	@import 'ComponentDropdown.scss';
	@import 'ComponentSidebar.scss';
	@import 'quill/dist/quill.snow.css';
	@import './ProseMirror.css';
	@import 'ComponentSlider.scss';
	@import 'PageHomeStyle.scss';
	@import 'PageRankingsStyle.scss';
	@import 'PageUserStyle.scss';
	@import 'PageChallengeStyle.scss';
	@import 'ComponentContentEditor.scss';
	@import "admin";
}



.exercise_chooser{
	$parentClass: &;
	margin-bottom: 20px;
	&__split{
		display: flex;
		position: relative;
		border: 1px solid white;
		min-height: 100px;
		border-color: #c7c7c7;
		margin-bottom: 20px;
		&:last-child{
			margin-bottom: 0;
		}
		&:hover{
			box-shadow: 0 0 10px rgba(0,0,0,.5);
		}
		cursor: pointer;
	}
	&__type_and_val{
		input{
			width: 60px;
		}
	}
	&__title{
		font-weight: bold;
		font-size: 16px;
		opacity: .5;
	}
	&__img{
		background:black;
		width: 150px;
		background-size: cover;
		background-position: center;
		opacity: .3;
	}
	&__rest{
		flex: 1;
		margin: calc(#{$mainSpace} / 2);
	}
	&__desc{
		opacity: .3;
	}
	&__btn{
		margin-top: -1px;
		margin-right: -1px;
	}
	&__split{
		&--included{
			cursor: auto;
			background: linear-gradient(to right, #3b3b3b, lighten(#3b3b3b, 3));
			border-color: #fff;
			box-shadow: 0 0 10px rgba(0,0,0,.5);
			#{$parentClass}__desc,
			#{$parentClass}__title,
			#{$parentClass}__img{
				opacity: 1;
			}
		}
	}
}

.exercises{
	$parentClass: &;
	&__split{
		display: flex;
		position: relative;
		border: 1px solid rgba(255, 255, 255, 0.3);
		min-height: 100px;
		margin-bottom: calc(#{$mainSpace} / 4);
		&:last-child{
			margin-bottom: 0;
		}
		&:hover{
			box-shadow: 0 0 10px rgba(0,0,0,.5);
		}
		cursor: pointer;
	}
	&__type_and_val{
		input{
			width: 60px;
		}
	}
	&__title{
		font-weight: bold;
		font-size: 16px;
		opacity: .5;
	}
	&__img{
		background:black;
		width: 150px;
		background-size: cover;
		background-position: center;
		opacity: .3;
	}
	&__rest{
		flex: 1;
		margin: calc(#{$mainSpace} / 2);
	}
	&__desc{
		opacity: .3;
	}
	&__btn{
		margin-top: -1px;
		margin-right: -1px;
	}
	&__split{
		&--included{
			cursor: pointer;
			background: linear-gradient(to right, #3b3b3b, lighten(#3b3b3b, 3));
			box-shadow: 0 0 10px rgba(0,0,0,.5);
			#{$parentClass}__desc,
			#{$parentClass}__title,
			#{$parentClass}__img{
				opacity: 1;
			}
		}
	}
}


.text--color{
	color: $colorMainDarker;
}

.categories {
	text-align: center;
	.title {
		font-weight: 700;
		margin-bottom: 15px;
	}
	&__list {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-template-rows: auto;
		grid-template-areas: "category-1 category-2 category-3 category-4"
							 "category-5 category-6 category-7 category-8";
		grid-gap: 10px;
		@media (max-width: 640px) {
			grid-template-columns: 1fr 1fr;
			grid-template-areas:
							"category-1 category-2"
							"category-3 category-4"
							"category-5 category-6"
							"category-7 category-8";
			align-items: center;
			background-size: auto;
		}
		.category {
			width: 100%;
			position: relative;
			cursor: pointer;
			background-color: #49457e;
			border-width: 1px;
			border-style: solid;
			border-image: $goldBorderGradient;
			border-image-slice: 1;
			background-image: url('./purple-gradient-bg.jpg');
			background-size: cover;
			background-position: center;
			padding: 25px 10px;
			filter: brightness(1);
			transition: filter 0.5s ease;
			&:nth-child(1) {
				grid-area: category-1;
			}
			&:nth-child(2) {
				grid-area: category-2;
			}
			&:nth-child(3) {
				grid-area: category-3;
			}
			&:nth-child(4) {
				grid-area: category-4;
			}
			&:nth-child(5) {
				grid-area: category-5;
			}
			&:nth-child(6) {
				grid-area: category-6;
			}
			&:nth-child(7) {
				grid-area: category-7;
			}
			&:nth-child(8) {
				grid-area: category-8;
			}
			&:hover {
				filter: brightness(1.3);
			}
			.icon {
				height: 85px;
				@media (max-width: 1024px) {
					height: 70px;
				}
				@media (max-width: 800px) {
					height: 50px;
				}
			}
			.title {
				color: white;
				font-size: 22px;
				margin-top: 20px;
				margin-bottom: 0;
				@media (max-width: 1024px) {
					font-size: 18px;
				}
				@media (max-width: 800px) {
					flex: 1;
					margin-top: 0px;
					font-size: 20px;
					text-align: center;
				}
				@media (max-width: 320px) {
					text-align: center;
				}
			}
			&--active{
				background-image: url('./purple-gradient-bg.jpg');
				filter: brightness(1.3);
				background-position: unset;
				&::before {
                    content: '';
                    position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
                    border-width: 5px;
                    border-style: solid;
                    border-image: $goldBorderGradient;
                    border-image-slice: 1;
                }
				.title {
					color: $goldColor;
				}
			}
			.voted {
				display: flex;
				justify-content: center;
				align-items: center;
				color: $goldColor;
				font-size: 18px;
				font-weight: 700;
				margin-top: 10px;
				svg {
					height: 20px;
					width: auto;
					margin-right: 5px;
				}
				@media (max-width: 800px) {
					margin-top: 0;
					flex-direction: column;
					font-size: 16px;
				}
			}
			&--voted {
				padding-bottom: 15px;
			}
		}
	}
}

.youtube-container {
	&__text {
		padding-top: 10px;
	}
}

.youtube {
	background: black;
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.sponsors {
	padding: 40px 0;
    box-sizing: border-box;
    background-color: #fff;
	width: 100%;
	@media (max-width: 1024px) {
		border-top: 1px solid $greyLineColor;
	}
    &__container {
		box-sizing: border-box;
		display: flex;
		max-width: 1280px;
		margin: 0 auto;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		a {
			min-width: 0;
			box-sizing: border-box;
			margin: 20px 30px;
			@media screen and (max-width: 960px) {
				margin: 15px 20px;
			}
			@media screen and (max-width: 480px) {
				margin: 10px 15px;
			}
			img {
				min-width: 0;
				height: auto;
				vertical-align: center;
				max-width: 150px; // 6 in row at 1280px

				@media screen and (max-width: 960px) {
				max-width: 140px; // 5 in row
				}
				@media screen and (max-width: 640px) {
				max-width: 110px; // 4 in row
				}
				@media screen and (max-width: 480px) {
				max-width: 80px;
				}
			}
		}
	}
}
.footer2018 {
	background: #1b1b1b;
	line-height: 16px;

	.footer2018__holder {
		padding: 20px 0;
		max-width: $maxWidth;
		margin: 0 auto;
		display: flex;
		font-size: 14px;
		color: #aaa;
		padding-left: $mainSpace;
        padding-right: $mainSpace;
        @media (max-width: 640px) {
            padding-left: $mainSpaceMobile;
            padding-right: $mainSpaceMobile;
        }

		a {
		color: #aaa;
		text-decoration: none;
		margin: 0 10px;
			&:hover {
				text-decoration: underline;
			}
		}

		.footer2018__legal {
			flex: 2;
			line-height: 1.5;
		}

		.footer2018__legal-links {
			a:first-child {
				margin-left: 0;
			}
		}

		.footer2018__please-help {
		flex: 1;
		text-align: right;
		align-self: center;
			a {
				font-weight: 700;
				margin: 0;
			}
		}

		@media screen and (max-width: 900px) {
			flex-direction: column;
			text-align: center;
			.footer2018__legal {
				padding-left: 0;
			}
			.footer2018__please-help {
				padding-right: 0;
				text-align: center;
				margin-top: 10px;
			}
		}
	}
}

.grd{
	display: block;
	margin: 10px auto;
	line-height: 0;
	&--hide{
		display: none;
	}
	&-img{
		cursor: pointer;
		transition: all .5s ease;
		z-index: 1;
		&:hover{
			z-index: 9999;
			box-shadow: 0 0 10px rgba(0,0,0,0.5);
			transform: scale(1.1);
			filter: brightness(1.1);
		}
	}
}
.grd-singleimage{
	position: relative;
	cursor: pointer;
	background: linear-gradient(to right, #453f68, #716e96, #453f68);
	&:hover {
		.grd-singleimage__close {
			opacity: 1;
		}
	}
	img {
		display: block;
		width: 100%;
		height: auto;
	}
	&--hide{
		display: none;
	}
	&__imgcontainer{
		max-width: 500px;
		margin: 0 auto;
	}
	&__close {
		padding: 10px;
		width: 30px;
		height: 30px;
		position: absolute;
		right: 0px;
		top: 0px;
		color: white;
		box-sizing: content-box;
		opacity: 0.5;
		transition: opacity 0.5s ease;
		filter: drop-shadow( 0px 0px 3px rgba(0, 0, 0, .6));
		@media (max-width: 640px) {
			opacity: 1;
		}
	}
}
.grd-separator{
	display: block;
	clear: both;
}
.grd-img{
	display: inline-block;
	background-size: cover;
	background-position: center;
}

#world-map {
	height: 100%;
	width: 100%;
	max-width: 1024px;
}

.leaflet-interactive {
	fill: rgba(46,46,46,0.8);
}

.leaflet-control-custom {
	background-color: white;
	width: 30px;
	height: 30px;
	font-size: 22px;
	text-align: center;

}

.leaflet-control-custom:hover {
	cursor: pointer;
}

#video_player {
	margin-top: 10px;
}

.leaflet-container {
	background: none;
}

.leaflet-popup-close-button {
	display: none;
}

.leaflet-popup .leaflet-zoom-animated {
	opacity: 0.5;
}

.leaflet-popup-content {
	width: 100%;
	margin: 20px;
}

.leaflet-popup-content-wrapper {
	outline: none;
	box-shadow: none;
	border-radius: 0;
	color: white;
	background-color: rgba(51, 45, 77, 0.9);
	text-align: center;
	font-size: 1.3vh;
	font-family: 'Open Sans';
}

.leaflet-popup-content-wrapper:after {
	position: absolute;
	width: 30px;
	height: 3px;
	margin-left: -15px;
	background: #000;
}

.leaflet-popup-tip {
	border: 0;
	box-shadow: none;
	background-color: rgba(51, 45, 77, 0.9);
}

#popup_container {
	height: 100%;
	max-height: 78vh;
}

#popup_bg {
	width: 100%;
	height: 100%;
	z-index: 1001;
	position: fixed;
	background-color: black;
	opacity: 0;
	visibility: hidden;
	transition: all 0.5s ease;
}

#popup_bg.hide {
	position: fixed;
	opacity: 0.7;
	top: 0;
	visibility: visible;
	left: 0;
}

#popup_close {
	float: right;
	font-weight: bold;
}

.map_header {
	position: absolute;
	font-size: 4.5vh;
	color: white;
	z-index: 1000;
	width: 100%;
	text-align: center;
	font-weight: 600;
	letter-spacing: 0.5vh;
	margin-top: 2.5vh;
}

.map_header span {
	font-weight: 100;
}

#popup_close:hover {
	transition: all 0.5s ease;
	cursor: pointer;
	color: $colorMainDarker;
}

#popup_container #popup {
	-webkit-font-smoothing: antialiased;
	font-family: 'open sans';
	transition: all 0.5s ease;
	visibility: hidden;
	opacity: 0;
	width: 59vh;
	position: fixed;
	background-color: white;
	border: 1px solid black;
	z-index: 1002;
	font-size: 25px;
	top: 20%;
	left: 47%;
	min-width: 678px;
	margin-left: -290px;
	@media screen and (max-width: 640px) {
		left: 0;
		top: 0;
		width: 100%;
		margin-left: 0;
		min-width: 1%;
	}
}

.popup_header {
	font-family: 'Open Sans';
	font-weight: bold;
	font-size: 16px;
	margin: 0px;
	display: inline-block;
}

.popup_header_country_flag {
	height: 24px;
	vertical-align: text-bottom;
	margin-left: 10px;
}

.popup_separator {
	height: 1px;
	background-color: white;
	margin: 14px 0;
	border: none;
	background-color: rgba(255, 255, 255, 0.5);
}

.popup_banner_img {
	width: 100%;
	height: auto;
}

.popup_title {
	font-family: 'Open Sans';
	font-weight: bold;
	font-size: 14px;
	margin-bottom: 10px;
}


